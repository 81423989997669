<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Socials from "../../sign_up/Socials.vue";

export default {
  components:{
    Loading,
    Socials
  },
  props: {
    isClassBooking: Boolean,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      error: null,
      email: ''
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get('/v3/users/profile').then(response => {
      this.email = /^guest_.*@soulartists.net$/.test(response.data.email) ? '' : response.data.email
      this.isLoading = false
    })
    .catch(error => {
        this.isLoading = false
      })
    this.$refs.email.focus()
  },
  methods: {
    setGuesToken() {
      let token = localStorage.getItem("token", "");
  
      if (token === null || token === "") {
        axios
          .get(`${window.location.origin}/api_tokens/new.json`)
          .then((response) => {
            if (this.isClassBooking){
              localStorage.setItem("class_booking", JSON.stringify(true))
            }
            localStorage.setItem("guest_token", response.data.jwt);
          });
      }
      else {
        if (this.isClassBooking){
          localStorage.setItem("class_booking", JSON.stringify(true))
        }
        localStorage.setItem("guest_token", token);
      }
    },
    signInUrl(){
      return `${window.location.origin}/users/sign_in`
    },
    nextStep() {
      let params = {
        user: {
          email: this.email
        }
      }
      this.isLoading = true
      this.$axios.put('/v3/users/profile', params)
        .then(response => {
          this.$emit('updateStep', { next_step: 'fill_phone' })
          this.isLoading = false
        })
        .catch(error => {
          this.error = error.response.data.errors.fields.email
          this.isLoading = false
        })
    },
    back() {
      this.$emit('updateStep', { next_step: 'fill_name' })
    },
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <div class="complete-signup-header__title">
      What is your
      <span class='complete-signup-header__primary_title'>email address?</span>
    </div>

    <p class="mt-4 mb-4">
      Provide your email address so we can send you any information regarding your booking
    </p>

    <div class="new-booking__content">
      <p class="mb-3">Enter your email</p>
      <div class="forms__item-100">
        <input
          v-model="email"
          type="email"
          :required="true"
          class="input order-capture-input border-0"
          v-bind:class="{ 'error': error}"
          placeholder="example@email.com"
          ref="email">
        <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
      </div>

      <div class="auth-form-content text-start mb-4">
        Already a member? <a @click="setGuesToken()" :href="signInUrl()">Log in</a>
      </div>


      <div class="mt-5">
        <v-app class="w-100 mb-4">
          <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
          <v-progress-linear
            rounded
            :value="bookingProgress"
            height="8">
          </v-progress-linear>
        </v-app>

        <div class="back-next">
          <button
            class="settings-forms__btn btn secondary fluid"
            @click="back()">
            Back
          </button>
          <button
            class="settings-forms__btn btn primary fluid"
            :disabled="!email || email === ''"
            @click="nextStep()">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
